// @flow

import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Routes from './Routes';
import Layout from './shared/Layout';
import { googleAnalyticsTrack } from '../utils/analytics';

const history = createBrowserHistory();
history.listen((location: Location): void => {
  googleAnalyticsTrack(location);
});

export default function App() {
  return (
    <Router history={history}>
      <Layout>
        <Routes />
      </Layout>
    </Router>
  );
}
